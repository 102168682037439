import { FC, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Tooltip } from 'antd'
import store from 'redux/store'
import { LogoFullColorSVGIcon } from 'imgs/svg/LogoFullColor'

import { ADMIN_ACTIVE_KEY_MENU } from 'router/type.d'
import { StoreType } from 'redux/type'
import { IProps, MenuItemType, IFolderMapping } from './type'
import UserService from 'services/user/user.service'
import UserGroupService from 'services/userGroup/index.service'
import AuthService from 'services/user/auth.service'
import { getListNonAccessModule } from 'pages/Login/helper'
import { setCurrentListNotAccess } from 'redux/reducers/listAccessSlice'
import ConfigInfoService from 'services/config/index.service'
import { ITypeConfig } from 'services/config/type.d'
import { setCurrentListCountriesAccess } from 'redux/reducers/listCountriesAccessSlice'
// import { setIsFlagMailingAddress } from 'redux/reducers/isFlagMailingAddress'
import { setIsFlagMailingAddress } from 'redux/reducers/isFlagMailingAddress'
import { setIsFlagInvoice } from 'redux/reducers/isFlagInvoice'
import { setCurrentListCountry } from 'redux/reducers/listCountrySlice'
import { setIsFlagDashboard } from 'redux/reducers/isFlagDashboard'

export const Menu: FC<IProps> = ({
  isCollapsed,
  setIsCollapsed
}: IProps): JSX.Element => {
  const history: any = useHistory()
  const [listAllModule, setListAllModule] = useState<any>()

  const userStr: string | null = localStorage.getItem('user')

  const isCustomerSupport: boolean | null = Boolean(
    sessionStorage.getItem('isCustomerSupport')
  )

  const prefixFolder: string = '/imgs/svg/'
  const folder: IFolderMapping = {
    active: 'light-white',
    inactive: 'primary-gradient'
  }

  const menuClasses: string = useMemo((): string => {
    return `gradient-layout__body__menu ${isCollapsed ? '-collaped' : ''}`
  }, [isCollapsed])

  const adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU = useSelector(
    (state: StoreType): ADMIN_ACTIVE_KEY_MENU =>
      state.router.current?.adminActiveMenuKey
  )

  const goTo: any = (path: string): void => {
    ConfigInfoService.getInfoFlag('dashboardAdminPortalFlag').then(
      (response: any): void => {
        store.dispatch(setIsFlagDashboard(response.data.flagValue))
        // store.dispatch(setIsFlagMailingAddress(false))
      }
    )
    ConfigInfoService.getInfoFlag('flagInvoiceLevelGeneration').then(
      (response: any): void => {
        store.dispatch(setIsFlagInvoice(response.data.flagValue))
      }
    )
    ConfigInfoService.getInfoFlag('flagMailingAddress').then(
      (response: any): void => {
        store.dispatch(setIsFlagMailingAddress(response.data.flagValue))
      }
    )
    UserService.getListModuleNotAccess().then((response: any): void => {
      const listData: string[] = getListNonAccessModule(
        listAllModule,
        response.data
      )

      store.dispatch(setCurrentListNotAccess(listData))
      localStorage.setItem('listNonAccess', JSON.stringify(listData))
    })
    history.push(path)
  }

  useEffect((): void => {
    if (userStr) {
      UserService.getListCountriesAccess()
        .then((res: any): void => {
          store.dispatch(setCurrentListCountriesAccess(res.data))
        })
        .catch((error: any): void => {})
      ConfigInfoService.getConfigInfo()
        .then((response: { data: ITypeConfig }): void => {
          const listCountry: string[] = response.data.country

          store.dispatch(setCurrentListCountry(listCountry))
        })
        .catch((error: any): void => {})
      ConfigInfoService.getInfoFlag('dashboardAdminPortalFlag').then(
        (response: any): void => {
          store.dispatch(setIsFlagDashboard(response.data.flagValue))
        }
      )
      ConfigInfoService.getInfoFlag('flagInvoiceLevelGeneration').then(
        (response: any): void => {
          store.dispatch(setIsFlagInvoice(response.data.flagValue))
        }
      )
      ConfigInfoService.getInfoFlag('flagMailingAddress').then(
        (response: any): void => {
          store.dispatch(setIsFlagMailingAddress(response.data.flagValue))
        }
      )
      UserGroupService.getListAccessRight()
        .then((res: any): void => {
          UserService.getListModuleNotAccess().then((response: any): void => {
            const listData: string[] = getListNonAccessModule(
              res.data,
              response.data
            )

            store.dispatch(setCurrentListNotAccess(listData))
            localStorage.setItem('listNonAccess', JSON.stringify(listData))
          })
          setListAllModule(res.data)
        })
        .catch((error: any): void => {})
    } else {
      AuthService.logout()
      history.push('/login')
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const menuItemList: MenuItemType[] = useMemo((): MenuItemType[] => {
    const getFolder: any = (name: ADMIN_ACTIVE_KEY_MENU): string => {
      const isActive: boolean = name === adminActiveMenuKey
      return `${prefixFolder}${isActive ? folder.active : folder.inactive}`
    }

    return isCustomerSupport
      ? [
          {
            path: '/',
            name: ADMIN_ACTIVE_KEY_MENU.DASHBOARD,
            text: 'Dashboard',
            isActive: adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.DASHBOARD,
            icon: `${getFolder(ADMIN_ACTIVE_KEY_MENU.DASHBOARD)}/apps.svg`
          },
          {
            path: '/customer-support/customers',
            name: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
            text: 'Customers',
            isActive: adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
            icon: `${getFolder(ADMIN_ACTIVE_KEY_MENU.CUSTOMERS)}/users.svg`
          },
          {
            path: '/salary-processing',
            name: ADMIN_ACTIVE_KEY_MENU.SALARY_PROCESSING,
            text: 'Salary Processing',
            isActive:
              adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.SALARY_PROCESSING,
            icon: `${getFolder(
              ADMIN_ACTIVE_KEY_MENU.SALARY_PROCESSING
            )}/wps.svg`
          },
          {
            path: '/card-management',
            name: ADMIN_ACTIVE_KEY_MENU.CARD_MANAGEMENT,
            text: 'Card Management',
            isActive:
              adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.CARD_MANAGEMENT,
            icon: `${getFolder(
              ADMIN_ACTIVE_KEY_MENU.CARD_MANAGEMENT
            )}/card-management.svg`
          },
          {
            path: '/customer-support/support',
            name: ADMIN_ACTIVE_KEY_MENU.SUPPORT,
            text: 'Support',
            isActive: adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.SUPPORT,
            icon: `${getFolder(ADMIN_ACTIVE_KEY_MENU.SUPPORT)}/support.svg`
          },
          {
            path: '/enquiry',
            name: ADMIN_ACTIVE_KEY_MENU.ENQUIRY,
            text: 'Enquiry',
            isActive: adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.ENQUIRY,
            icon: `${getFolder(ADMIN_ACTIVE_KEY_MENU.ENQUIRY)}/graph.svg`
          },
          {
            path: '/notifications',
            name: ADMIN_ACTIVE_KEY_MENU.NOTIFICATIONS,
            text: 'Notifications',
            isActive:
              adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.NOTIFICATIONS,
            icon: `${getFolder(ADMIN_ACTIVE_KEY_MENU.NOTIFICATIONS)}/noti.svg`
          },
          {
            path: '/reports',
            name: ADMIN_ACTIVE_KEY_MENU.REPORTS,
            text: 'Reports',
            isActive: adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.REPORTS,
            icon: `${getFolder(ADMIN_ACTIVE_KEY_MENU.REPORTS)}/pad.svg`
          },
          {
            path: '/internal',
            name: ADMIN_ACTIVE_KEY_MENU.ADMIN,
            text: 'Internal',
            isActive: adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.ADMIN,
            icon: `${getFolder(ADMIN_ACTIVE_KEY_MENU.ADMIN)}/folder.svg`
          },
          {
            path: '/settings',
            name: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
            text: 'Settings',
            isActive: adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.SETTINGS,
            icon: `${getFolder(ADMIN_ACTIVE_KEY_MENU.SETTINGS)}/setting.svg`
          }
        ]
      : [
          {
            path: '/',
            name: ADMIN_ACTIVE_KEY_MENU.DASHBOARD,
            text: 'Dashboard',
            isActive: adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.DASHBOARD,
            icon: `${getFolder(ADMIN_ACTIVE_KEY_MENU.DASHBOARD)}/apps.svg`
          },
          {
            path: '/customers',
            name: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
            text: 'Customers',
            isActive: adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
            icon: `${getFolder(ADMIN_ACTIVE_KEY_MENU.CUSTOMERS)}/users.svg`
          },
          {
            path: '/salary-processing',
            name: ADMIN_ACTIVE_KEY_MENU.SALARY_PROCESSING,
            text: 'Salary Processing',
            isActive:
              adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.SALARY_PROCESSING,
            icon: `${getFolder(
              ADMIN_ACTIVE_KEY_MENU.SALARY_PROCESSING
            )}/wps.svg`
          },
          {
            path: '/card-management',
            name: ADMIN_ACTIVE_KEY_MENU.CARD_MANAGEMENT,
            text: 'Card Management',
            isActive:
              adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.CARD_MANAGEMENT,
            icon: `${getFolder(
              ADMIN_ACTIVE_KEY_MENU.CARD_MANAGEMENT
            )}/card-management.svg`
          },
          {
            path: '/support',
            name: ADMIN_ACTIVE_KEY_MENU.SUPPORT,
            text: 'Support',
            isActive: adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.SUPPORT,
            icon: `${getFolder(ADMIN_ACTIVE_KEY_MENU.SUPPORT)}/support.svg`
          },
          {
            path: '/enquiry',
            name: ADMIN_ACTIVE_KEY_MENU.ENQUIRY,
            text: 'Enquiry',
            isActive: adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.ENQUIRY,
            icon: `${getFolder(ADMIN_ACTIVE_KEY_MENU.ENQUIRY)}/graph.svg`
          },
          {
            path: '/notifications',
            name: ADMIN_ACTIVE_KEY_MENU.NOTIFICATIONS,
            text: 'Notifications',
            isActive:
              adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.NOTIFICATIONS,
            icon: `${getFolder(ADMIN_ACTIVE_KEY_MENU.NOTIFICATIONS)}/noti.svg`
          },
          {
            path: '/reports',
            name: ADMIN_ACTIVE_KEY_MENU.REPORTS,
            text: 'Reports',
            isActive: adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.REPORTS,
            icon: `${getFolder(ADMIN_ACTIVE_KEY_MENU.REPORTS)}/pad.svg`
          },
          {
            path: '/internal',
            name: ADMIN_ACTIVE_KEY_MENU.ADMIN,
            text: 'Internal',
            isActive: adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.ADMIN,
            icon: `${getFolder(ADMIN_ACTIVE_KEY_MENU.ADMIN)}/folder.svg`
          },
          {
            path: '/settings',
            name: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
            text: 'Settings',
            isActive: adminActiveMenuKey === ADMIN_ACTIVE_KEY_MENU.SETTINGS,
            icon: `${getFolder(ADMIN_ACTIVE_KEY_MENU.SETTINGS)}/setting.svg`
          }
        ]

    // eslint-disable-next-line
  }, [adminActiveMenuKey])

  return (
    <div className={menuClasses}>
      <div
        className="gradient-layout__body__menu__item--wrap -branch"
        onClick={(): void => goTo('/')}
      >
        <div className="gradient-layout__body__menu__item">
          <div className="gradient-layout__body__menu__item__icon">
            <LogoFullColorSVGIcon />
          </div>

          <div className="gradient-layout__body__menu__item__text">
            Metis Admin Portal
          </div>
        </div>
      </div>

      {menuItemList.map((item: MenuItemType, index: number): JSX.Element => {
        return (
          <div
            key={`menu_item_${index}`}
            className="gradient-layout__body__menu__item--wrap"
            onClick={(): void => goTo(item.path)}
          >
            <a
              href={item.path}
              onClick={(event: any): void => event.preventDefault()}
            >
              <Tooltip placement="right" title={isCollapsed && item.text}>
                <div
                  className={`gradient-layout__body__menu__item ${
                    item.isActive ? '-active' : ''
                  }`}
                >
                  <div className="gradient-layout__body__menu__item__icon">
                    <img src={item.icon} alt="" />
                  </div>

                  <div className="gradient-layout__body__menu__item__text">
                    {item.text}
                  </div>
                </div>
              </Tooltip>
            </a>
          </div>
        )
      })}

      <div className="gradient-layout__body__menu__item--wrap">
        <div
          className={`gradient-layout__body__menu__item ${
            isCollapsed && '-collapsed-menu'
          }`}
          onClick={(): void => setIsCollapsed(!isCollapsed)}
        >
          <div className="gradient-layout__body__menu__item__icon">
            <img src={`/imgs/svg/${folder.inactive}/arrow-left.svg`} alt="" />
          </div>

          <div className="gradient-layout__body__menu__item__text">
            Minimize
          </div>
        </div>
      </div>

      <div className="gradient-layout__body__menu__cached-img-area">
        <img src="/imgs/svg/primary-gradient/apps.svg" alt="" />
        <img src="/imgs/svg/primary-gradient/users.svg" alt="" />
        <img src="/imgs/svg/primary-gradient/graph.svg" alt="" />
        <img src="/imgs/svg/primary-gradient/pad.svg" alt="" />
        <img src="/imgs/svg/primary-gradient/setting.svg" alt="" />
        <img src="/imgs/svg/primary-gradient/support.svg" alt="" />
        <img src="/imgs/svg/primary-gradient/arrow-left.svg" alt="" />
        <img src="/imgs/svg/primary-gradient/folder.svg" alt="" />
        <img src="/imgs/svg/primary-gradient/noti.svg" alt="" />
        <img src="/imgs/svg/primary-gradient/card-management.svg" alt="" />

        <img src="/imgs/svg/light-white/apps.svg" alt="" />
        <img src="/imgs/svg/light-white/users.svg" alt="" />
        <img src="/imgs/svg/light-white/graph.svg" alt="" />
        <img src="/imgs/svg/light-white/pad.svg" alt="" />
        <img src="/imgs/svg/light-white/setting.svg" alt="" />
        <img src="/imgs/svg/light-white/folder.svg" alt="" />
        <img src="/imgs/svg/light-white/support.svg" alt="" />
        <img src="/imgs/svg/light-white/noti.svg" alt="" />
        <img src="/imgs/svg/light-white/card-management.svg" alt="" />
      </div>
    </div>
  )
}

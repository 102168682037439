import { combineReducers } from 'redux'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import userSlice from './reducers/userSlice'
import spinSlice from './reducers/spinSlice'
import routerSlice from './reducers/routerSlice'
import listNotAccessSlice from './reducers/listAccessSlice'
import listCountrySlice from './reducers/listCountrySlice'
import listCountriesAccessSlice from './reducers/listCountriesAccessSlice'
import prevPathSlice from './reducers/prevPathSlice'
import idTokenSlice from './reducers/idTokenSlice'
import isDeactiveUserSlice from './reducers/isDeactiveUserSlice'
import featureFlagSlice from './reducers/featureFlagSlice'
import isFlagDashboardSlice from './reducers/isFlagDashboard'
import isFlagMailingAddressSlice from './reducers/isFlagMailingAddress'
import isFlagInvoiceSlice from './reducers/isFlagInvoice'

const customizedMiddleware: any = getDefaultMiddleware({
  serializableCheck: false
})

const persistConfig: any = {
  key: 'root',
  storage,
  whitelist: ['router']
}

const persistedReducer: any = persistReducer(
  persistConfig,
  combineReducers({
    prevPath: prevPathSlice,
    user: userSlice,
    spin: spinSlice,
    router: routerSlice,
    listNotAccess: listNotAccessSlice,
    listCountry: listCountrySlice,
    idToken: idTokenSlice,
    listCountriesAccess: listCountriesAccessSlice,
    isDeactiveUser: isDeactiveUserSlice,
    featureFlag: featureFlagSlice,
    isFlagDashboard: isFlagDashboardSlice,
    isFlagMailingAddress: isFlagMailingAddressSlice,
    isFlagInvoice: isFlagInvoiceSlice
  })
)

export default configureStore({
  reducer: persistedReducer,
  middleware: customizedMiddleware
})
